export default {
	data: () => ({
		user: {
			password: "",
			password_confirmation: ""
		},
		error: ""
	}),
	methods: {
		resetForm() {
			this.$refs.observer.reset();
			this.user = {
				password: "",
				password_confirmation: ""
			};
		},
		passchange() {
			let _vm = this;
			this.axios
				.post("/force-reset-password",{ password: this.user.password,
          password_confirmation: this.user.password_confirmation,
          token: this.$route.params.data}
        )
				.then(function (response) {
					window.location.href = '/auth/login'
          history.back();   
          history.go(-1);    
				})
				.catch(function () { });
		}
	},
	mounted() { }
};
